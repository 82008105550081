// ** Routes
import { routesArray } from "containers";

// #####################################################

export const servicePageNavItems = routesArray
	.filter(({ meta }) => meta.serviceNavItem)
	.sort((a, b) => {
		if (a.meta.serviceNavIndex > b.meta.serviceNavIndex) return 1;
		if (a.meta.serviceNavIndex < b.meta.serviceNavIndex) return -1;
		return 0;
	})
	.map((route) => ({
		key: route.key,
		textKey: route.meta.transKey || route.key,
		link: {
			to: {
				pathname: route.link,
				state: { forceReload: route?.key === "news" },
			},
		},
	}));

// #####################################################
